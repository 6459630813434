import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageUploader from './components/ImageUploader';
import styles from './App.module.css';
import './header-footer.css';
import ColorExtractorModel from './components/ColorExtractorModel';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function App() {
  const [imageData, setImageData] = useState(null);
  const [showHero, setShowHero] = useState(true);

  const heroBackground = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-image.png)`
  };

  const handleImageUpload = (data) => {
    setImageData(data);
    setShowHero(false);
  };

  return (
    <>
     <Navbar expand="lg" className='header' bg="dark" data-bs-theme="dark">
      <Container fluid>
        <Navbar.Brand href="#"><h2>ToneCompass</h2></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse className='justify-content-end' id="navbarScroll">
          
          <div >

          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="#" className='text-light'>Home</Nav.Link>
            <Nav.Link href="#" className='text-light'>About</Nav.Link>
            <Nav.Link href="#" className='text-light'>Contact</Nav.Link>
          </Nav>

          </div>

        </Navbar.Collapse>
      </Container>
    </Navbar>
      <main className={'text-center main'}>
      {/* {showHero && (
        <div className="hero">
          <h2>Color Matching Made Easy</h2>
        </div>
      )} */}
        <h1 className='mainTitle'>ToneCompass</h1>
        <div className={`${styles.mainContent} ${!imageData && 'hero'}`}>
          <ImageUploader onImageUpload={handleImageUpload} />
        </div>
        {imageData && <ColorExtractorModel imageData={imageData} />}
      </main>
      <footer className="footer">
        <p>&copy; 2023 ToneCompass. All rights reserved.</p>
      </footer>
    </>
  );
}

export default App;
