import React, {useContext } from 'react';
import { ChromePicker } from 'react-color';
import Draggable from 'react-draggable';
import styles from './ColorExtractor.module.css';
import { ColorExtractorApi } from './ColorExtractorModel';
import { Card, Button, Form } from 'react-bootstrap';


const ColorHandlerModel = () => {
    const { functionality, setFunctionality, selectedColor, canvasRef, paletteType, largeSwatchPosition, clickedColor, swatchesRef, colorSwatches, swatchPositions, setSwatchPositions, handleMouseLeave, handleMouseEnter, handleNearestColorClick, screenCapture, handleReset, hoveredColor, setPaletteType, setLargeSwatchPosition, draggableData, setDraggableData, rgbToHex } = useContext(ColorExtractorApi)
    return (
        <div >
            {selectedColor && (
                <div className={`${''} row`}>
                    <div className='col-12 mb-3 p-0 d-flex flex-column'>
                        <Card>
                            <Card.Header>
                                <h3>Functionality</h3>
                            </Card.Header>
                            <Card.Body>
                                <Form.Select value={functionality} className='w-100' onChange={(e) => setFunctionality(e.target.value)} aria-label="Default select example">
                                    <option value="nearest">Nearest Colours</option>
                                    <option value="palette">Colour Palette</option>

                                </Form.Select>


                                {/* <select value={functionality}className='w-100'  onChange={(e) => setFunctionality(e.target.value)}>
                            <option value="nearest">Nearest Colors</option>
                            <option value="palette">Color Palette</option>
                        </select> */}
                                {functionality === 'palette' && (
                                    <Form.Select value={paletteType} className='w-100' onChange={(e) => setPaletteType(e.target.value)} aria-label="Default select example">
                                        <option value="monochromatic">Monochromatic</option>
                                        <option value="complementary">Complementary</option>
                                        <option value="triadic">Triadic</option>
                                        <option value="analogous">Analogous</option>
                                    </Form.Select>

                                    // <select value={paletteType} className='w-100' onChange={(e) => setPaletteType(e.target.value)}>
                                    //     <option value="monochromatic">Monochromatic</option>
                                    //     <option value="complementary">Complementary</option>
                                    //     <option value="triadic">Triadic</option>
                                    //     <option value="analogous">Analogous</option>
                                    // </select>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-12 '>
                        <div className='row d-flex  flex-xxl-row align-items-start'>
                            <div className='col-xxl-6 col-12 mb-3 p-0'>
                                <Card>
                                    <Card.Header>
                                        <h3>Colour Swatches</h3>
                                        {/* <Draggable position={largeSwatchPosition} onStop={(_, data) => setLargeSwatchPosition({ x: data.x, y: data.y })}>
                                            <div
                                                className={`${styles.wavySwatch} ${styles.brushStroke}`}
                                                style={{
                                                    backgroundColor: `rgb(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b})`,
                                                    width: '100%',
                                                    height: '30px',
                                                    marginRight: '5px',
                                                }}
                                            />
                                        </Draggable> */}
                                    </Card.Header>
                                    <Card.Body className='d-flex align-item-center justify-content-center'>
                                    {!clickedColor && <Draggable position={largeSwatchPosition} onStop={(_, data) => setLargeSwatchPosition({ x: data.x, y: data.y })}>
                                            <div
                                                className={`${styles.wavySwatch} ${styles.brushStroke}`}
                                                style={{
                                                    backgroundColor: `rgb(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b})`,
                                                    width: '50px',
                                                    height: '100px',
                                                    margin: '5px',
                                                }}
                                                // style={{
                                                //     backgroundColor: `rgb(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b})`,
                                                //     width: '100%',
                                                //     height: '30px',
                                                //     marginRight: '5px',
                                                // }}
                                            />
                                        </Draggable>}
                                        {clickedColor && (
                                            <Draggable position={draggableData} onStop={(_, data) => setDraggableData({ x: data.x, y: data.y })}>
                                                <div
                                                    className={`${styles.wavySwatch} ${styles.brushStroke}`}
                                                    style={{
                                                        backgroundColor: `rgb(${clickedColor.r}, ${clickedColor.g}, ${clickedColor.b})`,
                                                        width: '50px',
                                                        height: '100px',
                                                        margin: '5px',
                                                    }}
                                                />
                                            </Draggable>
                                        )}
                                        <div
                                            ref={swatchesRef}
                                            id="swatches"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                flexWrap: 'wrap',
                                                border: '1px solid black',
                                                width: '300px',
                                                padding: '10px',
                                            }}
                                        >
                                            {colorSwatches.map((color, index) => (
                                                <Draggable
                                                    key={index}
                                                    position={swatchPositions[index] || { x: 0, y: 0 }}
                                                    onStop={(_, data) => {
                                                        setSwatchPositions({
                                                            ...swatchPositions,
                                                            [index]: { x: data.x, y: data.y },
                                                        });
                                                    }}
                                                >
                                                    <div
                                                        className={`${styles.wavySwatch} ${styles.brushStroke}`}
                                                        style={{
                                                            backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
                                                            width: '50px',
                                                            height: '50px',
                                                            margin: '5px',
                                                        }}
                                                        onMouseEnter={() => handleMouseEnter(color)}
                                                        onMouseLeave={handleMouseLeave}
                                                        onClick={() => handleNearestColorClick(color)}
                                                    />
                                                </Draggable>
                                            ))}
                                        </div>
                                    </Card.Body>
                                    <Card.Footer>
                                        <div>
                                            <Button variant="info" className='me-2' onClick={screenCapture}>Take a Screenshot</Button>
                                            <Button variant="warning" onClick={handleReset}>Reset</Button>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </div>
                            <div className={`${styles.colorInfo} ${canvasRef.height} align-items col-xxl-6 col-12 d-flex p-0 flex-column align-items-xxl-end align-items-center`}>
                                <Card>
                                    <Card.Header>
                                        <h3>Selected Colour</h3>
                                    </Card.Header>
                                    <Card.Body>
                                        <ChromePicker color={selectedColor} disableAlpha />
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                      <div className='col-12' style={{height: '48px'}}>
                      {clickedColor && (
                        <p>
                            Clicked color: RGB({clickedColor.r}, {clickedColor.g}, {clickedColor.b}) | HEX: {rgbToHex(clickedColor.r, clickedColor.g, clickedColor.b)}
                        </p>
                    )}
                    {hoveredColor && (
                        <p>
                            Hovered color: RGB({hoveredColor.r}, {hoveredColor.g}, {hoveredColor.b}) | HEX: {rgbToHex(hoveredColor.r, hoveredColor.g, hoveredColor.b)}
                        </p>
                    )}
                        </div>                                          

                   
                </div>
            )}
        </div>
    )
}

export default ColorHandlerModel