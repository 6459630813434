import React, { useState, useRef, useEffect, createContext } from 'react';
import Container from 'react-bootstrap/Container';
import ColorExtractor from './ColorExtractor'
import ColorHandlerModel from './ColorHandlerModel'
import Color from 'color';

export const ColorExtractorApi = createContext(null)
const ColorExtractorModel = ({ imageData }) => { 
    const canvasRef = useRef();
    const swatchesRef = useRef();
    const [selectedColor, setSelectedColor] = useState(null);
    const [hoveredColor, setHoveredColor] = useState(null);
    const [clickedColor, setClickedColor] = useState(null);
    const [functionality, setFunctionality] = useState('nearest');
    const [paletteType, setPaletteType] = useState('monochromatic');
    const [isImageVisible, setIsImageVisible] = useState(true);
    const [swatchPositions, setSwatchPositions] = useState({});
    const [largeSwatchPosition, setLargeSwatchPosition] = useState({ x: 0, y: 0 });
    const [draggableData, setDraggableData] = useState({ x: 0, y: 0 });

    const handleHideImage = () => {
        setIsImageVisible(false);
    };

    const handleShowImage = () => {
        setIsImageVisible(true);
    };


    useEffect(() => {
        drawImage();
    }, [imageData]);

    const drawImage = () => {
        if (!imageData || !canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');
        const img = new Image();
        img.src = imageData;
        
        img.onload = () => {
            // Calculate the height while maintaining the aspect ratio
            const aspectRatio = img.width / img.height;
            const canvasWidth = canvas.offsetWidth;
            const canvasHeight = canvasWidth / aspectRatio;
    
            // Set the canvas dimensions
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
    
            // Draw the image to the canvas, resizing it to fit
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        };

    };

    const handleClick = (event) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        const x = (event.clientX - rect.left) * scaleX;
        const y = (event.clientY - rect.top) * scaleY;
        const imageData = ctx.getImageData(x, y, 1, 1).data;
        const [r, g, b] = imageData;
        setSelectedColor({ r, g, b });
    };

    const handleMouseEnter = (color) => {
        setHoveredColor(color);
    };

    const handleMouseLeave = () => {
        setHoveredColor(null);
    };

    const handleNearestColorClick = (color) => {
        setClickedColor(color);
    };


    const screenCapture = async () => {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({
                video: { displaySurface: "window" },
            });
            const video = document.createElement("video");
            video.srcObject = stream;
            video.onloadedmetadata = () => {
                const canvas = document.createElement("canvas");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(video, 0, 0);
                const imgData = canvas.toDataURL("image/png");
                const link = document.createElement("a");
                link.href = imgData;
                link.download = "tonecompass.png";
                link.click();
                stream.getTracks().forEach((track) => track.stop());
            };
        } catch (error) {
            console.error("Error capturing the screen:", error);
        }
    };

    const getNearestColors = (color) => {
        const surroundingColors = [];
        const rows = 3;
        const columns = 3;
        const rowStep = 5;
        const columnStep = 5;

        const clamp = (value, min, max) => {
            return Math.min(Math.max(value, min), max);
        };

        for (let i = 1; i <= rows; i++) {
            for (let j = 1; j <= columns; j++) {
                surroundingColors.push({
                    r: clamp(color.r + i * rowStep, 0, 255),
                    g: clamp(color.g + j * columnStep, 0, 255),
                    b: clamp(color.b, 0, 255),
                });
            }
        }
        return surroundingColors;
    };

    const generateColorPalette = (baseColor, paletteType) => {
        const colorArray = [];
        const base = Color(baseColor);
        const adjust = (angle) => base.rotate(angle).rgb().object();
        switch (paletteType) {
            case 'monochromatic':
                for (let i = -50; i <= 50; i += 25) {
                    colorArray.push(base.darken(i / 100).rgb().object());
                }
                break;

            case 'complementary':
                colorArray.push(base.rgb().object(), adjust(180));
                break;

            case 'triadic':
                colorArray.push(base.rgb().object(), adjust(120), adjust(240));
                break;

            case 'analogous':
                colorArray.push(base.rgb().object(), adjust(-30), adjust(30));
                break;

            default:
                colorArray.push(base.rgb().object());
        }

        return colorArray;
    };

    const rgbToHex = (r, g, b) => {
        const hex = (r << 16) | (g << 8) | b;
        return '#' + hex.toString(16).padStart(6, '0');
    };


    const handleReset = () => {
        const newPositions = { ...swatchPositions };
        Object.keys(newPositions).forEach((key) => {
            newPositions[key] = { x: 0, y: 0 };
        });
        setSwatchPositions(newPositions);
        setLargeSwatchPosition({ x: 0, y: 0 });
        setDraggableData({ x: 0, y: 0 });
    };


    const colorSwatches = functionality === 'nearest'
        ? selectedColor ? getNearestColors(selectedColor) : []
        : generateColorPalette(selectedColor, paletteType);
    return (
        <ColorExtractorApi.Provider value={{ isImageVisible: isImageVisible, functionality: functionality, setFunctionality: setFunctionality, selectedColor: selectedColor, canvasRef: canvasRef, handleClick: handleClick, handleHideImage: handleHideImage, handleShowImage: handleShowImage, paletteType: paletteType, largeSwatchPosition: largeSwatchPosition, clickedColor: clickedColor, swatchesRef: swatchesRef, colorSwatches: colorSwatches, swatchPositions: swatchPositions, setSwatchPositions: setSwatchPositions, handleMouseLeave: handleMouseLeave, handleMouseEnter: handleMouseEnter, handleNearestColorClick: handleNearestColorClick, screenCapture: screenCapture, handleReset: handleReset, hoveredColor: hoveredColor, setPaletteType: setPaletteType, setLargeSwatchPosition: setLargeSwatchPosition, draggableData: draggableData, setDraggableData: setDraggableData, rgbToHex: rgbToHex }}>
            <Container>
            <div className='row justify-content-center'>
                <div className='col-12 col-lg-6'>
                    <ColorExtractor imageData={imageData} />
                </div>
                <div className={`${!selectedColor && 'd-none'} col-12 col-lg-6`}>
                    <ColorHandlerModel imageData={imageData} />
                </div>

            </div>
            </Container>
        </ColorExtractorApi.Provider>
    )
}

export default ColorExtractorModel