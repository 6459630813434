import React, { useRef, useState } from 'react';
import { BsUpload } from 'react-icons/bs';
import { Uploader } from "uploader";
import { UploadDropzone } from "react-uploader";

const ImageUploader = ({ onImageUpload }) => {
  const [imageUrl, setImageUrl] = useState('')
  const inputRef = useRef();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        onImageUpload(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Get production API keys from Upload.io
const uploader = Uploader({
  apiKey: "free"
});
// Customize the dropzone UI (see "customization"):
const options = { multi: false }


  return (
   <>
    <div className="uploadContainer">
      <label htmlFor="fileInput">
        <div className="dropzone">
          <BsUpload className="icon" />
          Click or drop an image here
        </div>
      </label>
    </div>
    <div>
      <input
        ref={inputRef}
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
    {/* <UploadDropzone uploader={uploader} // Required.
        options={options}         // Optional.
        width="600px"             // Optional.
        height="375px"            // Optional.
        onUpdate={files => {      // Optional.
          if (files.length === 0) {
            console.log('No files selected.')
          } else {
            console.log('Files uploaded:');
            console.log(files.map(f => f.fileUrl));
          }
        }} /> */}
   </>
  );
};

export default ImageUploader;
