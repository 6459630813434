import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './ColorExtractor.module.css';
import Webcam from "react-webcam";
import { ColorExtractorApi } from './ColorExtractorModel';
import { Card, Button } from 'react-bootstrap';

const ColorExtractor = () => {
    const { isImageVisible, canvasRef, handleClick, handleHideImage, handleShowImage } = useContext(ColorExtractorApi)
    const webcamRef = useRef(null)
    const [isWebcamVisible, setWebcamVisible] = useState(false);
    const [hasPermission, setHasPermission] = useState(false);

    // Function to handle the button click event
    const handleButtonClick = async () => {
        console.log('mediaStream', webcamRef.current);
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
            setHasPermission(true);
            setWebcamVisible(true);
            webcamRef.current.srcObject = mediaStream;
        } catch (error) {
            console.error('Error accessing webcam:', error);
        }
    };

    return (
        <div className={`flex-column m-auto mb-3 priveI mageModel`}>
            <div className={` ${isImageVisible ? "d-flex flex-column" : styles.hidden}`}>
                <Card>
                    <Card.Body >
                        <canvas
                            ref={canvasRef}
                            onClick={handleClick}
                            style={{
                                border: '1px solid black',
                                cursor: 'crosshair',
                                transition: 'transform 0.5s',
                                width: '100%',
                                // height: 'auto',
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <Button className='w-100' variant="primary" onClick={() => {
                            handleHideImage();
                            handleButtonClick()
                        }}>View Camera</Button>
                    </Card.Footer>
                </Card>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="svg-filters d-none">
                    <defs>
                        <filter id="brush-stroke">
                            <feTurbulence type="fractalNoise" baseFrequency="0.3" numOctaves="1" result="wavy" />
                            <feDisplacementMap in="SourceGraphic" in2="wavy" scale="10" xChannelSelector="R" yChannelSelector="G" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <div className={` ${!isImageVisible ? "d-flex flex-column" : 'd-none'}`}>
                <Card>
                    <Card.Body >
                        {hasPermission && isWebcamVisible && <Webcam ref={webcamRef} screenshotFormat="image/jpeg" style={{
                            border: '1px solid black',
                            cursor: 'crosshair',
                            transition: 'transform 0.5s',
                            width: '100%',
                            height: 'auto',
                        }} />}
                    </Card.Body>
                    <Card.Footer>
                        <Button className='w-100' variant="primary" onClick={handleShowImage}>Show Image</Button>
                    </Card.Footer>
                </Card>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="svg-filters d-none">
                    <defs>
                        <filter id="brush-stroke">
                            <feTurbulence type="fractalNoise" baseFrequency="0.3" numOctaves="1" result="wavy" />
                            <feDisplacementMap in="SourceGraphic" in2="wavy" scale="10" xChannelSelector="R" yChannelSelector="G" />
                        </filter>
                    </defs>
                </svg>
            </div>
            {/* <div className={`${styles.leftColumn} ${isImageVisible ? styles.hidden : ""}`}>
                <Webcam />
                <Button className='w-100' variant="primary" onClick={handleShowImage}>Show Image</Button>
            </div> */}
        </div>
    );
};

export default ColorExtractor;